import React from 'react';
import {useHubspotForm} from '@aaronhayes/react-use-hubspot-form';

const Form = Props => {
    const { loaded, error, formCreated } = useHubspotForm({
        portalId: '20339474',
        formId: 'd12f58b2-852d-450e-8776-9d89bc475a16',
        target: '#my-hubspot-form'
    });

    return (
      <div className="md:pt-0 pb-8 md:max-w-3xl md:mx-auto" id="my-hubspot-form"></div>
    )
}

export default Form
