import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import SEO from "../components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import Video from "../components/video"
import Form from "../components/form"

const IndexPage = () => (
  <>
    <SEO />
    <header className="relative md:pb-32">
      <div className="md:hidden px-4 pt-8 relative">
        <div className="relative z-20">
          <StaticImage src="../images/fv-01-sp.png" layout="constrained" loading="eager" placeholder="blurred" className="mr-24 block" alt="マコなり社長が「新しい挑戦」をするあなたを応援" />
        </div>
        <div className="absolute right-0 top-12 z-10">
          <StaticImage src="../images/fv.png" layout="constrained" loading="eager" placeholder="blurred" className="md:max-w-xl" alt="" />
        </div>
        <div className="bg-slate-100 -mx-4 mt-80 relative z-10 py-8 px-4">
          <StaticImage src="../images/fv-02-sp.png" layout="constrained" loading="eager" placeholder="blurred" className="" alt="MacBookプレゼントキャンペーン / マコなり社長Twitterをフォローして引用リツイート & 「新しいPCで新たに挑戦したいこと」をフォームで投稿" />
        </div>
      </div>

      <div className="md:max-w-6xl mx-auto hidden md:block pt-32 relative">
        <div className="max-w-3xl relative z-20">
          <StaticImage src="../images/fv-01-pc.png" layout="constrained" loading="eager" placeholder="blurred" className="" alt="マコなり社長が「新しい挑戦」をするあなたを応援" />
        </div>
        <div className="max-w-lg mt-16 relative z-20">
          <StaticImage src="../images/fv-02-pc.png" layout="constrained" loading="eager" placeholder="blurred" className="" alt="MacBookプレゼントキャンペーン / マコなり社長Twitterをフォローして引用リツイート & 「新しいPCで新たに挑戦したいこと」をフォームで投稿" />
        </div>

        <div className="absolute right-0 top-5 z-10">
          <StaticImage src="../images/fv.png" layout="constrained" loading="eager" placeholder="blurred" className="md:max-w-xl" alt="" />
        </div>

      </div>
    </header>
    <main className="max-w-5xl mx-auto shadow-xl relative z-40 bg-white md:-mt-12">
      <section className="pb-12 md:pb-24">
        <h2 className="bg-mk text-white">新しいPCで<br />「新たな挑戦」をしたい人を応援します</h2>
        <div className="max-w-5xl mx-auto px-6 md:px-12">
          <div className="my-10 md:my-16 max-w-3xl mx-auto shadow">
            <Video
              videoSrcURL="//www.youtube.com/embed/bMzxV3UM_64"
              videoTitle="マコなり社長"
            />
          </div>
          <p className="max-w-xl mx-auto tracking-wider text-center">
            2022年3月、起業10周年を記念して
            <br />
            <span className="border-b-4 border-amber-300 bg-amber-50">『マコなり起業10周年お祝いプレゼントキャンペーン』</span>を実施しました。
            <br />
            <br />
            視聴者・ファンの皆様からいただいた<br />お祝い金を次の挑戦者に渡す取り組みです。
            <br />
            集まった金額は<span className="border-b-4 border-amber-300 bg-amber-50">約400万円</span>。<br className="md:hidden" />本当にありがとうございます。
            <br />
            <br />
            私の挑戦は1台のMacBookから始まりました。
            <br />
            <br />
            当時はMac1台を買うことも難しくて、<br className="md:hidden" />銀行で引き出した<br />ほぼ全財産の現金を握りしめて<br className="md:hidden" />アップルストアに行ったことを<br />今でも覚えています。
            <br />
            初めて買ったMacBookは<br className="md:hidden" />最も大切な宝物でした。
            <br />
            <br />
            その1台のMacBookで<br className="md:hidden" />プログラミングを勉強して、<br className="md:hidden" />Webサービスをつくり、開発の仕事をはじめ、<br className="md:hidden" />日本最大級のプログラミングスクール<br className="md:hidden" />テックキャンプを始めとした<br className="md:hidden" />多くの事業が生まれたのです。
            <br />
            <br />
            あなたにも<span className="border-b-4 border-amber-300 bg-amber-50">Mac 1台から<br className="md:hidden" />始まる挑戦の物語</span>を始めてほしい。
            <br />
            <br />
            たとえば、プログラミング、デザイン、<br className="md:hidden" />動画編集、ライティング、<br className="md:hidden" />IT業界への転職や副業へのチャレンジ…<br className="md:hidden" />テクノロジー業界に関連した挑戦なら<br className="md:hidden" />内容は問いません。
            <br />
            <p className="text-center mt-8">
              あなたの最初の一歩を<br className="md:hidden" />踏み出せるキッカケになれるように
              <br />
              <b className="text-mk text-2xl block py-2">
                <span className="border-b-4 border-mk border-dashed">
                  #マコなり社長<br className="md:hidden" />MacBookプレゼント
                </span>
              </b>
              <b className="block">キャンペーンを開催します！ </b>
            </p>
          </p>
        </div>
      </section>
      <section className="pb-12 md:pb-24">
        <h2>プレゼント内容</h2> 
        <div className="max-w-3xl mx-auto px-6 md:px-0">
          <div className="md:flex items-center justify-center">
            <div className="flex-1">
              <h3 className="text-center md:text-left">
                最新のMacBook Air<small>※</small>を
                <br />
                抽選で40名様にプレゼント！
                <br />
                <small className="font-normal text-sm">※商品の発送は7月末までを予定しております</small>
              </h3>
              <div className="md:hidden my-6">
                <StaticImage src="../images/img-mac.png" layout="constrained" loading="eager" placeholder="blurred" className="" alt="MacBook Air" />
              </div>
              <p className="text-sm text-gray-500">
                ※MacBook Airは256GBストレージタイプとなります。
                <br />
                ※色の指定は出来かねますのでご了承ください。
                <br />
                ※プレゼントのバージョンにつきましては、予告なく変更する場合があります。
              </p>
            </div>
            <div className="flex-1 md:ml-8 hidden md:block">
              <StaticImage src="../images/img-mac.png" layout="constrained" loading="eager" placeholder="blurred" className="" alt="MacBook Air" />
            </div>
          </div>
        </div>
      </section>

      <section className="pb-12 md:pb-24 pt-12 px-2 md:px-8 mx-6 md:mx-10 mb-8 border-8 border-gray-100 rounded-lg">
        <div className="border-8 border-gray-100 rounded-lg text-center text-base md:text-2xl table mx-auto text-mk bg-white relative z-10  font-bold py-2 md:py-4 px-4 -mt-20 md:-mt-24">
          さ ら に
        </div>
        <h3 className="text-center text-xl md:text-2xl mb-8">
          <span className="text-lg md:text-xl text-mk pt-2 md:pb-2 md:px-4 md:mb-4 mb-8 inline-block mt-4 bg-white relative z-20 border-b-2 border-dashed border-mk md:border-b-0">
            先着500名限定
          </span>
          <hr className="border-t-2 border-b-2 rounded-md border-mk block -mt-10 relative z-10 mx-64" />
          <br/>
          <span className="border-b-4 border-amber-300 bg-amber-50">月2,970円</span>で質問し放題の<br className="md:hidden" />プログラミング学習サービス
          <br />
          テックスタディの<br className="md:hidden" /><span className="border-b-4 border-amber-300 bg-amber-50">1ヶ月無料クーポンプレゼント</span>
        </h3>
        <div className="max-w-3xl mx-auto px-4 md:px-12">
          <StaticImage src="../images/coupon.png" layout="constrained" loading="eager" placeholder="blurred" className="mx-auto block" alt="1ヶ月無料クーポン" />
          <p className="text-base md:text-xl text-center mb-8 mt-4 font-normal">
            キャンペーンにエントリー<br className="md:hidden" />いただいた方の中から<br className="md:hidden" />先着500名に
            <br />
            テックスタディが<span className="font-bold">初月無料</span>になる<br className="md:hidden" />クーポンをプレゼントします！
          </p>
          <div className="max-w-2xl block mx-auto md:bg-white md:py-4 md:px-16 md:md:border-gray-100">
            <StaticImage src="../images/TS_logo.png" layout="constrained" loading="eager" placeholder="blurred" className="md:mt-6 mb-4" alt="TECH STUDY by TECH CAMP" />
            <p className="font-normal text-center">
              テックスタディは月々2,970円で<br className="md:hidden" />質問し放題の環境<span className="text-xs text-gray-500">※</span>で<br />プログラミングを学べる定額制オンライン学習サービスです
            </p>
            <Link to="https://www.tech-study.in/?utm_source=makonari&utm_medium=mac_cp&utm_campaign=202205" target="_blank" className="block mx-auto max-w-sm my-4 py-4 text-center bg-white font-bold relative rounded text-black no-underline border border-gray-200">
              サービス詳細はこちら
              <div className="absolute top-7 right-4 w-2">
                <StaticImage src="../images/icn-right.png" layout="constrained" loading="eager" placeholder="blurred" className="w-2" alt=">" />
              </div>
            </Link>
            <p className="text-xs text-center text-gray-500 font-normal">
              ※時間帯によってはお時間をいただく場合がございます
            </p>
          </div>

          <div className='pt-8'>
            <h5 className="text-sm pb-2">＜クーポン関する注意事項＞</h5>
            <ul className="list-disc text-sm ml-4">
              <li className="pb-1 font-normal">STEP4を回答するための選考フォームと同じメール内に、クーポンコードを記載しています。</li>
              <li className="pb-1 font-normal">クーポンコードの有効期限は5月23日（月）20:00〜5月29日（日）23:59です。</li>
              <li className="pb-1 font-normal">クーポンコードはテックスタディのお支払い情報登録時にご入力いただく必要があります。</li>
              <li className="pb-1 font-normal">クーポンコードは先着500名様のみ利用可能です。上限に達した場合は、クーポンコードをご入力いただいた際に「カート内のアイテムに有効ではありません」というエラーが表示されます。その際は、期限内であってもクーポンコードはご利用いただけません。</li>
              <li className="pb-1 font-normal">決済完了後のクーポンの適用や返金は一切できかねます。クーポンコードの入力後、決済金額が0円になっていることを必ずご確認ください。クーポンコードの入力漏れ、適用漏れや誤入力には十分ご注意ください。</li>
              <li className="pb-1 font-normal">割引の対象は最初の1ヶ月の料金です。2ヶ月目以降の料金は割引の対象ではございません。</li>
              <li className="font-normal">既にテックスタディにご入会いただいている方はご利用いただけません。</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="pb-12 md:pb-24">
        <h2>10秒かんたんでエントリー完了</h2>
        <div className="max-w-3xl mx-auto px-6 md:px-0">
          <div className="md:flex items-baseline justify-center mb-8 md:mb-16">
            <p className="font-bold w-32 text-center text-mk md:text-left flex flex-wrap items-center mx-auto mb-4 md:mb-0 content-center justify-center md:justify-items-start">
              STEP
              <span className="text-7xl">1</span>
            </p>
            <div className="flex-1 w-full justify-center box border border-white py-8 px-8 bg-mk bg-opacity-5">
              <p className="mb-0 text-center">
                <span className="text-lg font-bold text-mk">氏名とメールアドレスを入力してエントリー</span>
                <Link to="#entry" className="block mx-auto max-w-sm my-4 py-4 text-center bg-white font-bold relative rounded text-black no-underline border border-gray-200 ring-offset-2 ring-mk ring-4">
                  エントリーはこちら
                  <div className="absolute top-7 right-4 w-2">
                    <StaticImage src="../images/icn-right.png" layout="constrained" loading="eager" placeholder="blurred" className="w-2" alt=">" />
                  </div>
                </Link>
                <small className="text-sm text-gray-500">
                  ※エントリーは1人1回までとさせていただきます。<br />複数回ご応募いただいた場合は無効となります。
                  <br />
                  よくあるご質問は<Link to="https://www.notion.so/FAQ-1d3e80ee1b9d4a7d8c7e4f5d361b5bc8" target="_blank">こちら</Link>
                </small>
              </p>
            </div>
          </div>
          <div className="md:flex items-baseline justify-center mb-8 md:mb-16">
            <p className="font-bold w-32 text-center text-mk md:text-left flex flex-wrap items-center mx-auto mb-4 md:mb-0 content-center justify-center md:justify-items-start">
              STEP
              <span className="text-7xl">2</span>
            </p>
            <div className="flex-1 w-full justify-center box border border-white md:pt-4 px-8 pt-8  bg-mk bg-opacity-5">
              <div className="md:flex items-center justify-center">
                <div className="flex-1">
                  <p className="text-center"><span className="text-lg font-bold text-mk">マコなり社長の<br />Twitterアカウントをフォロー</span></p>
                  <Link to="https://twitter.com/mako_yukinari" className="flex items-center justify-center my-4 py-4 text-center rounded bg-twitter text-white font-bold no-underline">
                  <StaticImage src="../images/icn-twitter.png" layout="constrained" loading="eager" placeholder="blurred" className="w-6 mr-2" alt="マコなり社長のTwitterアカウントをフォロー" />
                    @mako_yukinariをフォロー
                  </Link>
                </div>
                <div className="flex-1 max-w-sm mx-auto md:ml-8">
                  <StaticImage src="../images/twitter-follow.png" layout="constrained" loading="eager" placeholder="blurred" className="" alt="マコなり社長のTwitterアカウントをフォロー" />
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-baseline justify-center mb-8 md:mb-16">
            <p className="font-bold w-32 text-center text-mk md:text-left flex flex-wrap items-center mx-auto mb-4 md:mb-0 content-center justify-center md:justify-items-start">
              STEP
              <span className="text-7xl">3</span>
            </p>
            <div className="flex-1 w-full justify-center box border border-white md:pt-4 px-8 pt-8  bg-mk bg-opacity-5">
              <div className="md:flex items-center justify-center">
                <div className="flex-1">
                  <p className="text-center"><span className="text-lg font-bold text-mk">ハッシュタグ「#マコなり社長MacBookプレゼント」をつけて<br className="md:hidden" />マコなり社長の固定ツイートを<br className="md:hidden" />引用リツイート</span></p>
                  <Link to="https://twitter.com/mako_yukinari/status/1528692727747162119" className="block my-4 py-4 text-center bg-white font-bold relative rounded text-black no-underline border border-gray-200">
                    ツイートはこちら
                    <div className="absolute top-5 right-4 w-2">
                      <StaticImage src="../images/icn-right.png" layout="constrained" loading="eager" placeholder="blurred" className="w-2" alt=">" />
                    </div>
                  </Link>
                </div>
                <div className="flex-1 max-w-sm mx-auto md:ml-8">
                  <StaticImage src="../images/twitter-rt.png" layout="constrained" loading="eager" placeholder="blurred" className="" alt="ハッシュタグ「#マコなり社長MacBookプレゼント」をつけてマコなり社長の固定ツイートを引用リツイート" />
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-baseline justify-center">
            <p className="font-bold w-32 text-center text-mk md:text-left flex flex-wrap items-center mx-auto mb-4 md:mb-0 content-center justify-center md:justify-items-start">
              STEP
              <span className="text-7xl">4</span>
            </p>
            <div className="flex-1 w-full justify-center box border border-white py-8 px-8 bg-mk bg-opacity-5">
              <p className="mb-0 text-center">
                <span className="text-lg font-bold text-mk">STEP1で登録した<br className="md:hidden" />メールアドレスに<br />届いた選考フォーム<small>※</small>に回答</span>
                <br />
                <small className="text-xs md:text-sm text-gray-500">
                  ※2022年5月29日（日）23時59分までに<br />応募の流れのSTEP4まで行った方が抽選の対象です。
                </small>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-12 md:pb-24">
        <h2>応募期間</h2>
        <div className="max-w-5xl mx-auto px-6 md:px-12 text-center">
          <div className="md:flex items-center justify-center">
            <p className="box border border-white md:py-4 md:px-8 py-2 px-4 bg-mk bg-opacity-5 font-bold text-lg md:text-2xl text-mk">2022<small>年</small>5<small>月</small>23<small>日(月)</small> 20:00</p>
            <p className="px-8">〜</p>
            <p className="box border border-white md:py-4 md:px-8 py-2 px-4 bg-mk bg-opacity-5 font-bold text-lg md:text-2xl text-mk">2022<small>年</small>5<small>月</small>29<small>日(日)</small> 23:59</p>
          </div>
        </div>
      </section>

      <section className="pb-12 md:pb-24" id="entry">
        <h2>エントリーフォーム</h2>
        <div className="max-w-5xl mx-auto md:px-12">
          <div className="md:p-8 bg-white shadow-sm mb-8 px-6 pt-6">
            <Form />
          </div>
          <p className="md:text-center px-6">よくあるご質問は<Link to="https://www.notion.so/FAQ-1d3e80ee1b9d4a7d8c7e4f5d361b5bc8" target="_blank">こちら</Link></p>
        </div>
      </section>

      <section className="pb-12 md:pb-24">
        <h2>応募要項</h2>
        <div className="max-w-3xl mx-auto px-6 md:px-12">

          <h4>賞品内容・当選者数</h4>
          <p>
            MacBook Air 1台（40名様）
            <br />
            <small className="text-sm text-gray-500 font-normal">
              ※MacBook Airは256GBストレージタイプとなります。
              <br />
              ※色の指定は出来かねますのでご了承ください。
              <br />
              ※賞品のバージョンにつきましては、予告なく変更する場合があります。
              <br />
                ※エントリーは1人1回までとさせていただきます。複数回ご応募いただいた場合は無効となります。
            </small>
          </p>

          <h4>応募期間</h4>
          <p>
            2022年5月23日（月）20:00　〜　2022年5月29日（日）23:59
            <br />
            <small className="text-sm text-gray-500 font-normal">※2022年5月29日（日）23時59分までに応募の流れのSTEP4まで行った方が抽選の対象です。</small>
          </p>

          <h4>応募条件</h4>
          <ul className="ml-8 list-disc mb-4 font-normal">
            <li>Twitterアカウントをお持ちであること。</li>
            <li>応募期間内にマコなり社長のTwitterアカウントをフォローしていること。</li>
            <li>応募期間内にハッシュタグ #マコなり社長MacBookプレゼント をつけて、該当ツイートを引用リツイートしていること。</li>
            <li>鍵付きのTwitterアカウントではないこと。</li>
            <li>Twitterの仕様上、第三者がツイートを閲覧できないアカウントでの応募は無効になる場合がございます。詳しくは、Twitterルールをご確認ください。</li>
          </ul>
          <p className="text-sm text-gray-500 font-normal pb-4">
            ※応募期間内及び応募期間後もマコなり社長のTwitterアカウントのフォローは外さないようにお願いします。外した期間がある場合、応募が無効となる可能性がございますのでご注意ください。
          </p>

          <h4>応募方法</h4>
          <ol className="ml-8 list-decimal mb-4  font-normal">
            <li>本ページ内の<Link to="#entry">エントリーフォーム</Link>より氏名とメールアドレスを入力し送信する</li>
            <li>マコなり社長のTwitterアカウントをフォローする</li>
            <li>ハッシュタグ 「#マコなり社長MacBookプレゼント」をつけてマコなり社長のツイートを引用リツイートする</li>
            <li>「1.」で登録したメールアドレスに届いた選考フォームに必要事項を記載して送信する</li>
          </ol>
          <p className="text-sm text-gray-500 font-normal pb-4">
            ※選考フォームの情報は事務局にて確認し、キャンペーンにそぐわないと判断した場合は、応募が無効になる場合がございます。
          </p>

          <h4>当選発表</h4>
          <p>
            MacBook Airの発送をもって当選発表とさせていただきます。
            <br />
            <small className="text-sm text-gray-500 font-normal">
              ※企画内容や抽選方法、抽選結果に関するお問い合わせは一切受け付けません。
              <br />
              ※不明点につきましては、<Link to="https://www.notion.so/FAQ-1d3e80ee1b9d4a7d8c7e4f5d361b5bc8" target="_blank">よくある質問（FAQ）</Link>をご確認ください。
              <br />
              ※関係者へのTwitterのDM（ダイレクトメッセージ）でのお問い合わせについては、誠に恐れ入りますがお受けできませんのであらかじめご了承ください。
              <br />
              ※当選者様には本人確認書類の提出を行なっていただく可能性がございます。
              <br />
              ※厳正な抽選の上、当選者を決定致します。
              <br />
              ※当選者の方への賞品の発送をもって当選発表と代えさせて頂きます。
              <br />
              ※賞品の発送先は日本国内に限定致します。
              <br />
              ※賞品の発送にあたり、発送方法・発送日程・時間帯指定等のご希望にはお答えできません。
              <br />
              ※配達時にご不在であった場合は、宅配便の不在票にてご対応お願いします。保管期限内にお受け取り頂けない場合も再送は致しません。
              <br />
              ※ご当選者様の住所、転居先不明・長期不在などにより賞品をお届けできない場合には、当選を無効とさせて頂きます。
              <br />
              ※都合により賞品の発送が多少遅れる場合もございます。
              <br />
              ※賞品に関するご使用上のトラブルについては賞品パッケージに記載の製造会社又は販売会社にお問い合わせください。
            </small>
          </p>

          <h4>運営事務局について</h4>
          <p className="font-normal">
            本キャンペーンは、株式会社NOANSWERが主催し、キャンペーン事務局（以下、事務局）が運営しております。
            <br />
            なお、適正な運用を行うために事務局が必要と判断した場合に限り、本キャンペーンの応募条件変更・中止等、あらゆる対応をとることができるものとさせていただきます。あらかじめご了承ください。詳しくは<Link to="#notice">注意事項</Link>をご確認ください。
          </p>

          <h4 id="notice">注意事項</h4>
          <p className="font-normal">
            本キャンペーンについては下記をお読みいただき、ご同意いただける場合のみご応募ください。
          </p>

          <ul className="ml-8 list-disc mb-8 text-sm text-gray-500 font-normal">
            <li>応募は1人1回限りです。複数回の応募をされた場合は全ての応募が無効となります。</li>
            <li>本キャンペーンに応募するにあたり、応募者はキャンペーンの運用について事務局の運用方法に従うものとし、一切異議申立てを行わないものとします。</li>
            <li>本キャンペーンに想定以上の応募があった場合、応募期間内であっても応募を締め切る場合があります。</li>
            <li>本キャンペーンについての連絡及び賞品発送に関して、事務局からご連絡をさせていただく場合がございます。</li>
            <li>未成年者のご応募の場合、応募をもって法定代理人（親権者等）が同意したものとみなします。</li>
            <li>本キャンペーンページの利用・利用停止、または不能による損害については事務局は一切責任を負いかねます。（各種Webサービスのサーバダウン等も含む）</li>
            <li>本キャンペーンはパソコン、スマートフォンからのご応募となります。稀に、機種によってはご利用できない場合がございます。フィーチャーフォン（ガラケー）からのご応募はできません。</li>
            <li>インターネット接続料及び通信料はお客様のご負担となります。</li>
            <li>賞品の内容及びデザインは変更となる場合がございます。あらかじめご了承ください。</li>
            <li>当選の権利は当選者ご本人のみに帰属します。当選の権利・賞品の第三者への譲渡及び換金は固く禁止いたします。</li>
            <li>当選の権利や賞品をオークション等に出品して換金することは固くお断りいたします。オークション等への出品が確認された場合は、当選を無効とし、賞品をご返却いただく場合がございます。</li>
            <li>ご当選者様の賞品送付先として同一の住所や、電話番号が複数登録された場合、応募を無効とさせていただく場合がございます。あらかじめご了承ください。</li>
            <li>当社が意図しない不正な処理を行わせるプログラム等でのご応募は無効とさせていただきます。</li>
            <li>当サイトの利用によるいかなるトラブルや損害（直接、間接の損害別を問わず）が発生したとしても株式会社NOANSWERは一切の責任を負いかねます。あらかじめご了承ください。</li>
            <li>ご応募いただく際にお預かりする皆様の個人情報の保護方針については、「プライバシーポリシー」に記載しておりますので、必ずご一読の上、ご利用願います。</li>
            <li>株式会社NOANSWERおよび関係者は応募できません。</li>
          </ul>

          <h4>個人情報の共同利用について</h4>
          <p className="font-normal">
            株式会社NOANSWERは、株式会社divと本キャンペーンにて応募者様より受領した個人データを、商品のご案内の目的で、共同して利用いたします。なお、個人データの管理責任者はキャンペーン事務局となります。
          </p>

          <h4>賠償責任</h4>
          <p className="font-normal">
            本サイトの利用に関連して利用者と第三者との間に紛争が発生した場合には、利用者は、自身の責任と費用で当該紛争を解決するものとし、株式会社NOANSWERに損害を与えることのないようにするものとします。
          </p>

          <h4>免責事項</h4>
          <p className="font-normal">
            本サイトの利用に関連して利用者または第三者に損害が発生した場合、株式会社NOANSWERは一切責任を負いかねます。
            <br />
            また、本キャンペーンの規約に違反していると事務局が判断した場合に限り、アカウントのブロック、当選の取り消し等の必要な措置をとることができるものとし、これに関連して利用者に損害が発生した場合についても事務局は責任を負わないものとします。
          </p>

          <h4>準拠法および裁判管轄</h4>
          <p className="font-normal">
            本キャンペーン規約の解釈・適用に関しましては、日本国法に準拠するものといたします。
            <br />
            また、本キャンペーン規約に関する紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          </p>
        </div>
      </section>
      <section className="pb-12 md:pb-24">
        <h2>よくある質問</h2>
        <p className="md:text-center px-6">よくあるご質問は<Link to="https://www.notion.so/FAQ-1d3e80ee1b9d4a7d8c7e4f5d361b5bc8" target="_blank">こちら</Link></p>
      </section>

    </main>
    <footer className="p-12 text-center">
      <ul className="md:flex justify-center">
        <li>
          <p className="px-4">株式会社NOANSWER</p>
        </li>
        <li>
          <Link to="https://www.notion.so/e8c0ff8e3b854627b97af00386cbb8a2" className="px-4" target="_blank">個人情報の共同利用について</Link>
        </li>
      </ul>
    </footer>
  </>
)

export default IndexPage
